import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from "react-query";
import axios from "axios";
import { globalHistory, Location, navigate } from "@reach/router";
import { useState } from "react";
import decryptCode from "../utils/decryptCode";
import useUpload from "./useUpload";

const RadioContext = createContext({
  nameseo: null,
  isTask: false,
  currentRadioid: () => {},
  setCurrentRadioid: () => {},
});
const UserContext = createContext(null);

export const apiQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
});

const DEVELOPMENT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const API_PROXY = DEVELOPMENT
  ? "http://localhost:3009/millsonic/proxy/"
  : "https://millsonic.com/api/";
export const API_SONICOLA_URL = DEVELOPMENT
  ? "http://localhost:3009/millsonic/sonicola/"
  : "https://apissl.millsonic.com/sonicola/v1/";
export const API_BACKEND_URL = DEVELOPMENT
  ? "http://localhost:3009/millsonic/backend/"
  : "https://apissl.millsonic.com/backend/v1/";

function fetchPost(method, dataToSend) {
  // console.log("fetchPost", method, dataToSend);
  return async () => {
    const { data } = await axios.post(API_SONICOLA_URL + method, dataToSend);
    // console.log("fetchPost data", data);
    if (typeof data?.data !== "undefined") {
      return data.data;
    }
    return data;
  };
}

function fetchPostPage(method, initialParams) {
  return async (params) => {
    const sendParams =
      params.pageParam !== undefined ? params.pageParam : initialParams;
    // console.log("fetchPostPage", method, initialParams);
    const { data } = await axios.post(API_SONICOLA_URL + method, sendParams);
    return data;
  };
}

export const useLocals = (lat, lng, search) => {
  /**
   * @type DataToSend
   * @property {number} limit
   * @property {number} offset
   * @property {number} latitude
   * @property {number} longitude
   * @property {string*} search
   */
  const dataToSend = {
    limit: 100,
    offset: 0,
    latitude: -34.90198799981324,
    longitude: -56.13432873602192,
    search: "",
  };
  if (lat !== undefined && lng !== undefined && lat !== null && lng !== null) {
    dataToSend.latitude = lat; //-34.90198799981324
    dataToSend.longitude = lng; //-56.13432873602192,
  }
  if (search !== "" && search !== undefined) {
    dataToSend.search = search;
  }
  const query = useQuery(
    ["locals", lat, lng, search],
    fetchPost(`locals`, dataToSend),
    {
      enabled:
        (search !== "" && search !== undefined) ||
        (lat !== null &&
          lng !== null &&
          lat !== undefined &&
          lng !== undefined),
    }
  );
  return query;
};

export const useRadioidByNameseo = (nameseo) => {
  const getRadioQuery = useQuery(
    [`getradionameseo`, nameseo],
    async () => {
      const params = { nameseo: nameseo };
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .post(`${API_BACKEND_URL}getradionameseo`, params)
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  if (typeof response.data !== "undefined") {
                    if (typeof response.data.data !== "undefined") {
                      // console.log(
                      //   "getradionameseo response.data.data",
                      //   response.data.data
                      // );
                      onResolve(response.data.data);
                    } else {
                      // console.log(
                      //   "getradionameseo response.data",
                      //   response.data
                      // );
                      onResolve(response.data);
                    }
                  } else {
                    // console.log("getradionameseo response", response);
                    onResolve(response);
                  }
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        !!nameseo &&
        nameseo !== undefined &&
        nameseo !== null &&
        typeof nameseo === "string",
      refetchInterval: 10 * 1000,
    }
  );

  return {
    ...getRadioQuery,
    radioid: getRadioQuery.data?.radioid ? getRadioQuery.data?.radioid : null,
    name: getRadioQuery.data?.name ?? null
  };
};

export const useLocal = (radioid) => {
  const { currentRadioid, setCurrentRadioid } = useContext(RadioContext);

  useEffect(() => {
    if (radioid !== undefined && currentRadioid !== radioid) {
      setCurrentRadioid(parseInt(radioid));
    }
  }, [radioid, currentRadioid, setCurrentRadioid]);

  const query = useQuery(
    [`singlecola`, parseInt(currentRadioid)],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .post(`${API_SONICOLA_URL}singlecola`, {
              radioid: currentRadioid,
            })
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  if (typeof response.data.data !== "undefined") {
                    // console.log(
                    //   "singlecola response.data.data",
                    //   response.data.data
                    // );
                    onResolve(response.data.data);
                  } else {
                    // console.log("singlecola response.data", response.data);
                    onResolve(response.data);
                  }
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        !isNaN(currentRadioid) &&
        currentRadioid !== null &&
        currentRadioid !== undefined,
      refetchInterval: 10 * 1000,
    }
  );

  return {
    ...query,
    radioid: currentRadioid,
    nameseo: query.data?.nameseo ? query.data?.nameseo : null,
  };
};

export const useTerms = (nameseo) => {
  const { user } = useUser();
  const [form, setForm] = useState(null);
  const { radioid, data: radioData } = useRadioidByNameseo(nameseo);

  const extraQuery = useQuery(
    ["extra", radioid, user?.userid],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(
              `${API_PROXY}getUserExtra?radioid=${radioid}&token=${user.token}`
            )
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  if (typeof response?.data?.extra === "string") {
                    console.log(
                      "extra",
                      response.data.extra.replaceAll("'", '"')
                    );
                    response.data.extra = JSON.parse(
                      response.data.extra.replaceAll("'", '"')
                    );
                  }
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled: !!radioid && !!user?.token,
    }
  );

  const termsQuery = useQuery(
    ["terms", nameseo, user?.userid],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(`${API_PROXY}getTermsForm/${nameseo}`)
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled: !!nameseo,
    }
  );

  const acceptTerms = useMutation(async (extra) => {
    try {
      if (user?.userid === undefined || user?.userid === null) {
        throw new Error("user not logged");
      }
      if (radioid === undefined || radioid === null) {
        throw new Error("Radio not selected");
      }
      const data = await new Promise((onResolve, onReject) =>
        axios
          .get(
            `${API_PROXY}addUserExtra?${new URLSearchParams({
              radioid: radioid,
              token: user?.token,
              extra: extra,
            }).toString()}`
          )
          .then((response) => {
            if (Object.hasOwnProperty.call(response, "status")) {
              if (response.status === 200) {
                onResolve(response.data);
              }
            }
            onReject();
          })
          .catch((error) => {
            console.log("Handle error", error);
            onReject(error);
          })
      );
      return data;
    } catch (e) {
      throw e;
    }
  });

  useEffect(() => {
    if (termsQuery?.data?.text) {
      const dom = document.createElement("div");
      dom.innerHTML = termsQuery.data.text;
      const _form = dom.querySelector("form");
      console.log("has form?", _form);
      if (_form) {
        const formGroups = _form.querySelectorAll(".form-group");
        const inputs = [];
        formGroups.forEach((formGroup) => {
          const label = formGroup.querySelector("label");
          const input = formGroup.querySelector("input");
          const small = formGroup.querySelector("small");
          const link = label.querySelector("a");
          inputs.push({
            label,
            input,
            small,
            link,
          });
        });
        setForm(inputs);
      } else {
        setForm(null);
      }
    }
  }, [termsQuery?.data]);

  const links = useMemo(() => {
    if (form) {
      const links = [];
      form.forEach(({ link }) => {
        if (link) {
          links.push(link);
        }
      });
      return links;
    }
    return null;
  }, [form]);

  return {
    ...termsQuery,
    acceptTerms: acceptTerms,
    radioid: radioid,
    radioData: radioData,
    form: form,
    links: links,
    extraQuery: extraQuery,
  };
};

export const useRadioByNameseo = () => {
  const { nameseo } = useContext(RadioContext);

  // useEffect(() => {
  //   console.log("useTask useEffect change radioid", radioid);
  // }, [radioid]);

  const getRadioQuery = useQuery(
    [`getradionameseo`, nameseo],
    async () => {
      try {
        const params = { nameseo: nameseo };
        const data = await new Promise((onResolve, onReject) =>
          axios
            .post(`${API_BACKEND_URL}getradionameseo`, params)
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        nameseo !== undefined &&
        nameseo !== null &&
        typeof nameseo === "string",
      refetchInterval: 10 * 1000,
    }
  );

  return {
    getRadioQuery: getRadioQuery,
    radioid: getRadioQuery?.data?.radioid,
    nameseo: nameseo,
  };
};

export const useNextSong = (radioid) => {
  const { data: localData } = useLocal(radioid);

  const actualRadioid = useMemo(() => {
    return localData ? localData.radioid : radioid;
  }, [localData, radioid]);

  const query = useQuery(
    [`nextsong`, actualRadioid],
    fetchPost(`nextsong`, {
      // token: token,
      radioid: actualRadioid,
      limit: 100,
      offset: 0,
    }),
    {
      enabled: actualRadioid !== null && typeof actualRadioid !== "undefined",
      refetchInterval: 10000,
    }
  );

  return {
    ...query,
    data: query?.data,
  };
};

export const useUser = () => {
  const {
    token,
    user,
    setUser,
    login,
    logout,
    userInitiated,
    loginRedirect,
    setLoginRedirect,
  } = useContext(UserContext);

  const userQuery = useQuery(
    [`user`, token, "user"],
    async () => {
      const qF = fetchPost("user", { token: token });
      const data = await qF();
      setUser({ ...data, token: token });
      return data;
    },
    { enabled: token !== null }
  );

  const setRadioVisited = (radioinfo) => {
    if(!radioinfo) return;
    const existingRadio = JSON.parse(window.localStorage.getItem('radio')) ?? {};
    const updatedRadio = { ...existingRadio, ...radioinfo};
    window.localStorage.setItem('radio', JSON.stringify(updatedRadio));
  };

  const getRadioInfo = () => {
    return JSON.parse(window.localStorage.getItem('radio') ?? null);
  }

  // Define the "login" mutation
  apiQueryClient.setMutationDefaults("login", {
    mutationFn: async (dataToSend) => {
      let url = `login`;
      if (dataToSend?.type === "facebook" || dataToSend?.type === "google" || dataToSend?.type === 'apple') {
        url = `addUser`;
      }
      // console.log("login", url, dataToSend);
      const qF = fetchPost(url, dataToSend);
      return await qF();
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      if (result !== null && result.hasOwnProperty("token")) {
        // Replace optimistic todo in the user list with the result
        apiQueryClient.setQueryData("login", (old) => result);
        login(result.token, result);
      }
      return result;
    },
    onError: (error, variables, context) => {
      // console.log("on login error");
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("login", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("login");
  const loginData = mutation.data;

  const editMutation = useMutation({
    mutationFn: async (dataToSend) => {
      const { data } = await axios.post(
        API_SONICOLA_URL + "editUser",
        dataToSend
      );
      return data;
    },
    onSuccess: (result, variables, context) => {
      console.log("on edit success", result);
    },
    onError: (error, variables, context) => {
      console.log("on edit error", error);
    },
    onSettled: (data, error, variables, context) => {
      console.log("on edit settled", data, error);
      apiQueryClient.invalidateQueries("user");
    },
  });

  const updateUser = () => {
    apiQueryClient.invalidateQueries("user");
  };

  const { upload } = useUpload();

  const uploadAvatarMutation = useMutation({
    mutationFn: async (dataToSend) => {
      const { data } = await upload(
        API_SONICOLA_URL + "uploadImage",
        dataToSend
      );
      return data;
    },
    onSuccess: (result, variables, context) => {
      console.log("on upload avatar success", result);
      if (typeof result.status === "string" && result.status === "success") {
        editMutation.mutate({ ...user, avatar: result.url });
      }
    },
    onError: (error, variables, context) => {
      console.log("on upload avatar error", error);
    },
    onSettled: (data, error, variables, context) => {
      console.log("on upload avatar settled", data, error);
    },
  });

  return {
    mutation: mutation,
    userQuery: userQuery,
    login: mutation.mutate,
    logout: logout,
    loginData: loginData,
    user: user,
    setRadioVisited: setRadioVisited,
    getRadioInfo: getRadioInfo,
    token: token,
    logged: token !== null,
    initiated: userInitiated,
    updateUser: updateUser,
    loginRedirect: loginRedirect,
    setLoginRedirect: setLoginRedirect,
    editUser: editMutation.mutate,
    editUserMutation: editMutation,
    uploadAvatar: uploadAvatarMutation.mutate,
    uploadAvatarMutation: uploadAvatarMutation,
  };
};

export const useFavorites = () => {
  const [mutatingObserver, setMutatingObserver] = useState(false);
  const { token } = useUser();

  const favoritesQuery = useQuery(
    ["favorites", token],
    fetchPost(
      "favorites",
      {
        token: token,
        action: "list",
      },
      { enabled: token !== null }
    )
  );

  // Define the "addfavorite" mutation
  apiQueryClient.setMutationDefaults("addfavorite", {
    mutationFn: async (dataToSend) => {
      const { data } = await axios.post(`${API_SONICOLA_URL}favorites`, {
        ...dataToSend,
        token: token,
      });
      return data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      updateFavorites();
      setMutatingObserver(true);
      return result;
    },
    onError: (error, variables, context) => {
      console.log("on addfavorite error");
      updateFavorites();
      setMutatingObserver(true);
    },
    retry: 1,
  });

  const mutation = useMutation("addfavorite");

  const updateFavorites = () => {
    apiQueryClient.invalidateQueries("favorites");
  };

  if (!favoritesQuery.isFetching && mutatingObserver) {
    setMutatingObserver(false);
  }

  return {
    ...favoritesQuery,
    mutation: mutation,
    isMutating: mutation.isLoading || mutatingObserver,
    addFavorite: (songid) => {
      mutation.mutateAsync({ action: "add", songid: songid });
    },
    removeFavorite: (songid) => {
      mutation.mutateAsync({ action: "remove", songid: songid });
    },
    updateFavorites: updateFavorites,
  };
};

/**
 * Creates a react-query query for calling "songlist"
 * @param {string} playlistid
 * @param {string} search
 * @param {number} offset
 * @returns {object}
 */
export const useSongs = (playlistid, search, radioid) => {
  const { token } = useUser();

  const SONGS_PER_PAGE = 20;

  const dataToSend = {
    token: token,
    offset: 0,
    limit: SONGS_PER_PAGE,
    radioid: radioid,
  };

  if (search !== "") {
    dataToSend.filter = search;
  } else if (playlistid !== "") {
    dataToSend.playlistid = playlistid;
  }

  const songsQuery = useInfiniteQuery(
    ["songlist", token, dataToSend.radioid, playlistid, search],
    fetchPostPage("songlist", dataToSend, { enabled: token !== null }),
    {
      getNextPageParam: (lastPage, pages) => {
        // console.log("getNextPageParam", lastPage, pages);
        const pageParams = {
          token: token,
          offset: pages.length * SONGS_PER_PAGE,
          limit: SONGS_PER_PAGE,
          radioid: dataToSend.radioid,
        };
        if (search !== "") {
          pageParams.filter = search;
        }
        if (playlistid !== "") {
          pageParams.playlistid = playlistid;
        }
        return pageParams;
      },
      enabled:
        (typeof dataToSend.radioid === "string" ||
          typeof dataToSend.radioid === "number") &&
        (playlistid !== "" || search !== ""),
      keepPreviousData: true,
      staleTime: 1000,
      getPreviousPageParam: (firstPage, pages) => {
        if (pages.length === 0) return undefined;
        return (pages.length - 1) * SONGS_PER_PAGE;
      },
    }
  );

  console.log("songsQuery", songsQuery?.data);

  const songs = songsQuery.data?.pages
    ?.map((page) => page.data)
    .flat()
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.songid === value.songid)
    );

  return {
    songs: songs,
    total: songsQuery?.data?.pages[0].total,
    loadMore: songsQuery.fetchNextPage,
    ...songsQuery,
  };
};

export const useSingleSong = (radioid, songname, songid) => {
  const { user } = useUser();
  const { token } = user;

  const parts =
    typeof songname === "string" && songname !== ""
      ? songname.split(" - ")
      : [songname];

  let filter = songname;

  if (parts.length > 0) {
    parts.shift();
    filter = parts.join(" ");
  }
  filter = filter.slice(0, 8);

  const dataToSend = {
    token,
    limit: 1000,
    offset: 0,
    radioid,
    filter,
  };

  const songQuery = useQuery(
    ["singlesong", radioid, songname, songid],
    fetchPost("songlist", dataToSend)
  );

  return {
    ...songQuery,
    song: songQuery.data?.find((song) => song.songid === songid),
  };
};

export const usePlaylist = (
  radioid,
  playlistid,
  random = true,
  offset = 0,
  limit = 30
) => {
  const { user } = useUser();
  const { token } = user;
  const playlistQuery = useQuery(
    ["playlist", playlistid, radioid, offset, limit],
    fetchPost("songlist", {
      token,
      limit,
      offset,
      radioid,
      playlistid,
    })
  );

  const songs = useMemo(() => {
    const data = playlistQuery?.data?.data
      ? playlistQuery?.data?.data
      : playlistQuery?.data
      ? playlistQuery?.data
      : [];
    // console.log("playlistQuery data", playlistQuery?.data);
    // console.log("songs data", data);
    if (random) {
      return data.sort(() => Math.random() - 0.5);
    }
    return data;
  }, [playlistQuery.data, random]);

  return { ...playlistQuery, songs };
};

export const useVote = (radioid) => {
  const { user } = useUser();
  const { token } = user;

  // Define the "vote" mutation
  apiQueryClient.setMutationDefaults("vote", {
    retries: 1,
    mutationFn: async (params) => {
      if (params.scid !== undefined) {
        const dataToSend = {
          token: token,
          votes: params.votes,
          scid: params.scid,
          radioid: radioid,
        };
        const response = await axios.post(
          `${API_SONICOLA_URL}votesong`,
          dataToSend
        );
        console.log(
          "votesong response.data",
          typeof response.data,
          response?.data
        );
        if (typeof response.data === "object") {
          return response.data;
        } else {
          return null;
        }
      } else {
        const dataToSend = {
          token: token,
          radioid: radioid,
          votes: params.votes,
          songid: params.songid,
        };
        const response = await axios.post(
          `${API_SONICOLA_URL}requestsong`,
          dataToSend
        );
        console.log(
          "requestsong response.data",
          typeof response.data,
          response.data
        );
        if (typeof response.data !== "undefined") {
          return response.data;
        } else {
          console.log("requestsong response", response);
          return response;
        }
      }
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("vote", (old) => result);
      apiQueryClient.invalidateQueries(["singlecola"]);
      apiQueryClient.invalidateQueries(["nextsong"]);
      apiQueryClient.invalidateQueries(["user"]);
      return result;
    },
    onError: (error, variables, context) => {
      console.log("on vote error", error, error.response, variables, context);
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("vote", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("vote");

  const vote = (params) => {
    mutation.mutateAsync(params);
  };

  const voteData = mutation.data;

  return {
    mutation: mutation,
    vote: vote,
    voteData: voteData,
  };
};

export const useCode = () => {
  const { user, logged } = useUser();
  const token = logged ? user?.token : null;

  // Define the "colaCode" mutation
  apiQueryClient.setMutationDefaults("colaCode", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        ccode: params.ccode,
      };
      const response = await axios.post(
        `${API_SONICOLA_URL}validateColaCode`,
        dataToSend
      );
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("colaCode", (old) => result);
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on colaCode error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("colaCode", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("colaCode");

  const sendCode = useCallback(
    async (
      params,
      successCallback = () => {},
      errorCallback = () => {},
      doneCallback = () => {}
    ) => {
      try {
        const codeResponse = await mutation.mutateAsync(params);
        if (codeResponse !== undefined) {
          successCallback(codeResponse);
        }
      } catch (error) {
        console.error("sendcode error", error);
        errorCallback(error);
      } finally {
        doneCallback();
      }
    },
    [mutation]
  );

  const codeData = mutation.data;

  return {
    mutation: mutation,
    sendCode: sendCode,
    codeData: codeData,
  };
};

export const useCards = () => {
  const { user } = useUser();
  const { token } = user;

  const cardsQuery = useQuery(
    ["cards", token],
    fetchPost("cards", { token: token }),
    { enabled: token !== null }
  );

  const updateCards = () => {
    apiQueryClient.invalidateQueries("cards");
  };

  // Define the "addCard" mutation
  apiQueryClient.setMutationDefaults("addCard", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        name: params.name,
        card_num: params.card_num,
        exp_month: params.exp_month,
        exp_year: params.exp_year,
        stripeToken: params.stripeToken,
        cvc: params.cvc,
      };
      const response = await axios.post(
        `${API_SONICOLA_URL}addCard`,
        dataToSend
      );
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      console.log("on addCard success", result);
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("addCard", (old) => result);
      apiQueryClient.invalidateQueries("cards");
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on addCard error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("addCard", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const addCardMutation = useMutation("addCard");

  const addCard = async (params, callback = () => {}) => {
    console.log("useApi addCard ", params);
    try {
      const codeResponse = await addCardMutation.mutateAsync(params);
      if (codeResponse !== undefined) {
        callback();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const addCardData = addCardMutation.data;

  // Define the "deleteCard" mutation
  apiQueryClient.setMutationDefaults("deleteCard", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        ucid: params.ucid,
      };
      const response = await axios.post(
        `${API_SONICOLA_URL}deletecard`,
        dataToSend
      );
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("deleteCard", (old) => result);
      apiQueryClient.invalidateQueries("cards");
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on deleteCard error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("deleteCard", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const deleteCardMutation = useMutation("deleteCard");

  const deleteCard = async (params, callback = () => {}) => {
    try {
      const codeResponse = await deleteCardMutation.mutateAsync(params);
      if (codeResponse !== undefined) {
        callback();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const deleteCardData = deleteCardMutation.data;

  return {
    cardsQuery: cardsQuery,
    cards: cardsQuery.data?.cards,
    isLoading: cardsQuery.isLoading,
    isError: cardsQuery.isError,
    updateCards: updateCards,
    error: cardsQuery.error,
    addCardMutation: addCardMutation,
    addCard: addCard,
    addCardData: addCardData,
    deleteCardMutation: deleteCardMutation,
    deleteCard: deleteCard,
    deleteCardData: deleteCardData,
  };
};

export const ApiProvider = ({ children }) => {
  const [currentRadioid, setCurrentRadioid] = useState(null);
  const [user, setUser] = useState(null);
  const [initiated, setInitiated] = useState(false);
  const [token, setToken] = useState(null);
  const isBrowser = useCallback(() => typeof window !== "undefined", []);

  const [loginRedirect, setLoginRedirect] = useState("/sonicboxes");

  useEffect(() => {
    const savedLogin = window.localStorage.getItem("redirectLogin");
    if (typeof savedLogin === "string" && savedLogin !== "") {
      setLoginRedirect(savedLogin);
    }
  }, []);

  const saveUser = useCallback((user) => {
    window.localStorage.setItem("sonicboxUser", JSON.stringify(user));
  }, []);

  const getUser = useCallback(() => {
    if (isBrowser() && window.localStorage.getItem("sonicboxUser")) {
      const storedUser = JSON.parse(
        window.localStorage.getItem("sonicboxUser")
      );
      // console.log("storedUser", storedUser)
      if (storedUser.hasOwnProperty("token") && storedUser.token !== null) {
        return storedUser;
      }
    }
    return { token: null, name: "" };
  }, [isBrowser]);

  useEffect(() => {
    if (user === null) {
      const storedUser = getUser();
      if (storedUser.hasOwnProperty("token") && storedUser.token !== null) {
        setUser(storedUser);
        setToken(storedUser.token);
      }
    } else {
      console.log("save user", user);
      saveUser(user);
    }
    setInitiated(true);
  }, [user, getUser, saveUser]);

  const login = useCallback((newToken, newUser) => {
    setToken(newToken);
    setUser(newUser);
    setTimeout(() => {
      const redirectLogin = window.localStorage.getItem("redirectLogin");
      if (typeof redirectLogin === "string" && redirectLogin !== "") {
        window.localStorage.removeItem("redirectLogin");
        navigate(redirectLogin);
      } else {
        navigate("/sonicboxes");
      }
    }, 500);
  }, []);

  const logout = useCallback(
    (callback) => {
      apiQueryClient.removeQueries(["user", token]);
      console.log("logout");
      console.log(window.FB);
      if (window?.FB) {
        // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
        window.FB.api("/me/permissions", "delete", null, (...args) => {
          console.log("FB delete permissions callback", args);
          window.FB.logout();
        });
      }
      setUser({ token: null, name: "" });
      saveUser("");
      setToken(null);
      setCurrentRadioid(null);
      navigate("/");
      if (typeof callback === "function") callback();
    },
    [token, setUser, setToken, saveUser]
  );

  useEffect(() => {
    globalHistory.listen(({ pathname }) => {
      console.log("pathname", pathname);
    });
  }, []);

  return (
    <Location>
      {({ location }) => {
        const matchedRadio = location.pathname.match(/\/sonicbox\/(.+)/);
        const radioNameseo =
          matchedRadio !== null ? parseInt(matchedRadio[1]) : null;
        const matchedTaskNameseo = location.pathname.match(/\/task\/(.+)/);
        const taskNameseo =
          matchedTaskNameseo !== null ? matchedTaskNameseo[1] : null;
        const isTask = taskNameseo !== null;

        return (
          <RadioContext.Provider
            value={{
              nameseo: isTask ? taskNameseo : radioNameseo,
              isTask: isTask,
              currentRadioid: currentRadioid,
              setCurrentRadioid: setCurrentRadioid,
            }}
          >
            <UserContext.Provider
              value={{
                user: user,
                setUser: setUser,
                token: token,
                userInitiated: initiated,
                setToken: setToken,
                login: login,
                logout: logout,
                loginRedirect: loginRedirect,
                setLoginRedirect: setLoginRedirect,
              }}
            >
              <QueryClientProvider client={apiQueryClient}>
                <StoredCodesProvider>{children}</StoredCodesProvider>
              </QueryClientProvider>
            </UserContext.Provider>
          </RadioContext.Provider>
        );
      }}
    </Location>
  );
};

const StoredCodesProvider = ({ children }) => {
  const isBrowser = useCallback(() => typeof window !== "undefined", []);
  const { logged, updateUser } = useUser();
  const { sendCode } = useCode();
  const [sentCode, setSentCode] = useState(null);

  const removeStoredCode = useCallback(
    (storedCode) => {
      const currStored =
        isBrowser() && window.localStorage.getItem("sonicbox-codes")
          ? JSON.parse(window.localStorage.getItem("sonicbox-codes"))
          : [];
      console.log("currStored", currStored, storedCode);
      const storedCodeIndex = currStored.indexOf(storedCode);
      console.log(storedCodeIndex);
      if (storedCodeIndex > -1) {
        currStored.splice(storedCodeIndex, 1);
        if (isBrowser) {
          window.localStorage.setItem(
            "sonicbox-codes",
            JSON.stringify(currStored)
          );
        }
        setSentCode(null);
      }
    },
    [isBrowser]
  );

  useEffect(() => {
    const storedCodes =
      isBrowser() && window.localStorage.getItem("sonicbox-codes")
        ? JSON.parse(window.localStorage.getItem("sonicbox-codes"))
        : [];
    // console.log("useEffect in useCode", storedCodes);
    if (logged && storedCodes.length > 0) {
      const firstCode = storedCodes[0];
      // console.log(storedCodes);
      const splitted = decryptCode(firstCode).split("-");
      if (splitted.length > 0) {
        const codeToSend = splitted[0];
        if (codeToSend.length > 0 && sentCode !== codeToSend) {
          setSentCode(codeToSend);
          sendCode(
            { ccode: codeToSend },
            (response) => {
              updateUser();
              console.log("response", response);
              if (
                response.hasOwnProperty("status") &&
                response.status === "success"
              ) {
                removeStoredCode(firstCode);
                setSentCode(null);
              }
            },
            (error) => {
              console.log("sendcode error", error);
              if (error.hasOwnProperty("response")) {
                console.log("error response", error.response);
                if (error.response?.data?.msg === "Code does not work") {
                  removeStoredCode(firstCode);
                  setSentCode(null);
                  alert("El código ya no es válido");
                }
              }
            }
          );
        }
      }
    }
  }, [logged, sendCode, sentCode, removeStoredCode, isBrowser, updateUser]);
  return children;
};

export const getNameseoByRadioid = async (radioid) => {
  const response = await axios.post(`${API_SONICOLA_URL}singlecola`, {
    radioid,
  });
  console.log("response", response);
  if (response?.data?.data?.data?.nameseo) {
    return response.data.data.data.nameseo;
  }
  if (response?.data?.data?.nameseo) {
    return response.data.data.nameseo;
  }
  if (response?.data?.nameseo) {
    return response.data.nameseo;
  }
  return null;
};

export const useContact = () => {
  const mutation = useMutation(
    (data) => axios.post(`${API_SONICOLA_URL}sendMessage`, data),
    {
      onSuccess: (data) => {
        console.log("data", data);
      },
    }
  );
  return mutation;
};
