import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";

import { useTerms, useUser } from "../../../hooks/useApi";

import DevPre from "../../dev/DevPre";
import Loader from "../../basic/Loader";
import FacebookButton from "../../FacebookButton";
import AppleButton from "../../AppleButton";
import GoogleButton from "../../GoogleButton";

import SonicboxLogoPNG from "../../../images/sonicbox-logo.png";
import "../../../styles/index.scss";

import { T } from "../../../hooks/useLanguage";
import TermsAndConditionsContent from "../../TermsAndConditionsContent";

export default function Home() {
  const { logged, loginRedirect } = useUser();
  const [isLoading] = useState(false); // setIsLoading
  const [enabled, setEnabled] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const radioNameseo =
    loginRedirect.indexOf("/sonicbox/") === 0
      ? loginRedirect.replace("/sonicbox/", "")
      : null;

  const terms = useTerms(radioNameseo);

  useEffect(() => {
    // console.log("useEffect logged", logged);
    if (logged) {
      navigate("/sonicboxes");
    }
  }, [logged, loginRedirect]);

  // console.log("isLoading", isLoading, "logged", logged);

  useEffect(() => {
    if (terms?.data && terms?.data?.status === "success") {
      setShowTerms(true);
    }
  }, [terms?.data]);

  // useEffect(() => {
  //   if (terms?.radioid) {
  //     if (enabled) {
  //       window.localStorage.setItem(`tempterms`, terms.radioid);
  //     } else {
  //       window.localStorage.removeItem(`tempterms`);
  //     }
  //   }
  // }, [terms?.radioid, enabled]);

  return (
    <div className="container index-page">
      <img src={SonicboxLogoPNG} alt="Sonicbox logo" className="page-logo" />
      <p>
        <T>home.welcome</T>
      </p>
      <DevPre>
        {typeof logged} - {logged ? "logged" : "not logged"}
      </DevPre>
      <h1>
        <T>home.title</T>
      </h1>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {radioNameseo && (
            <TermsAndConditionsContent
              nameseo={radioNameseo}
              style={{ marginTop: 40, marginBottom: 40 }}
              onAccept={setEnabled}
            />
          )}
          <AppleButton enabled={enabled || !showTerms}/>
          {/*<FacebookButton enabled={enabled || !showTerms} />*/}
          <GoogleButton enabled={enabled || !showTerms} />
          <p>
            <Link to="/login" disabled={!enabled && showTerms}>
              <T>home.mustlogin</T>
            </Link>
          </p>
        </>
      )}
    </div>
  );
}
