import React, {useEffect, useState} from "react";
import AppleLogin from 'react-apple-login'
import {APPLE_APP_ID} from "../constants";
import {useUser} from "../hooks/useApi";
import useLanguage, {T} from "../hooks/useLanguage";
import Button from "./basic/Button";
import Loader from "./basic/Loader";
import { isIOS } from 'react-device-detect';

export default function AppleButton({enabled = true}) {
    const {i18n} = useLanguage();
    const {login, mutation} = useUser();

    const [errorMessage, setErrorMessage] = useState("");

    const responseApple = (response) => {
        if (response?.error) {
            setErrorMessage(i18n.t('home.appleerror'));
            return;
        }

        if (response?.authorization?.id_token) {
            const dataToSend = {
                email: response?.user?.email ?? "",
                oauthid: response.authorization.id_token,
                name: response?.user?.name?.firstName ?? "",
                oauth: response.authorization.code,
                picture: "",
                type: "apple",
            };
            login(dataToSend);
        }
    };

    useEffect(() => {
        console.log("errorMessage sideEffects", errorMessage);
        if (errorMessage !== "") {
            setTimeout(() => {
                setErrorMessage("");
            }, 3500);
        }
    }, [errorMessage]);

    return (
        <>
            {isIOS && (
                <AppleLogin
                    clientId={APPLE_APP_ID}
                    redirectURI="https://fd46-2800-a4-162f-400-812-d782-5e2-1c8.ngrok-free.app/"
                    autoLoad={false}
                    scope={"name email"}
                    state={"state"}
                    nonce={"nonce"}
                    callback={responseApple}
                    usePopup={true}
                    render={(renderProps) => (
                        <div>
                            <Button
                                onClick={renderProps.onClick}
                                className="apple"
                                disabled={renderProps.disabled || !enabled}
                                autoLoad={false}
                            >
                                <div>
                                    {mutation?.isLoading ? <Loader/> : <T>home.loginapple</T>}
                                </div>
                            </Button>
                            {errorMessage !== "" ? (
                                <div className="facebook-error-msg">{errorMessage}</div>
                            ) : null}
                        </div>
                    )}
                />
            )}
        </>
    );
}
