import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  ADD_CREDITS_SUCCESS_MODAL,
  BRANCH_OPTIONS,
  SELECT_BRANCH_MODAL,
  TERMS_AND_CONDITIONS_MODAL,
  VOTED_SUCCESS_MODAL,
} from "../../../constants";
import { scrollIntoView } from "../../../utils/domManipulation";
import { useModal } from "../../../hooks/useModal";
import usePrevious from "../../../hooks/usePrevious";
import {
  useLocal,
  useRadioidByNameseo,
  useTerms,
  useUser,
} from "../../../hooks/useApi";
import { useScrollObserver } from "../../../hooks/useScrollObserver";

import DevPre from "../../dev/DevPre";

import Button from "../../basic/Button";
import Loader from "../../basic/Loader";

import Sticky from "../../layout/Sticky";

import Boxes from "./components/Boxes";
import NowPlaying from "./components/NowPlaying";

import "./styles/sonicbox.scss";
import MenuToggleSVG from "../../../inline-svg/menu-toggle.svg";
import useLanguage, { T } from "../../../hooks/useLanguage";

import PlayIcon from "../../../images/icon-play.png";
import { useLocation, useNavigate } from "@reach/router";
import SearchMusic from "./components/SearchMusic";
import SonicboxHeader from "./components/SonicboxHeader";
import Suggested from "./components/Suggested";
import Artists from "./components/Artists";
import useTheme from "../../../hooks/useTheme";
import { getCookie } from "../../../helpers/cookies";

const Sonicbox = ({ nameseo }) => {
  const { setTheme } = useTheme();
  const { i18n } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  const { openModal: openSelectBranchModal } = useModal(SELECT_BRANCH_MODAL);
  const { stackModal, lastModal, openModal } = useModal(
    ADD_CREDITS_SUCCESS_MODAL
  );
  const {
    openModal: openTermsModal,
    currentModal,
    closeModal,
  } = useModal(TERMS_AND_CONDITIONS_MODAL);
  const [openPeekTerms, setOpenPeekTerms] = useState(false);
  const { extraQuery, ...termsQuery } = useTerms(nameseo);

  const { user, setRadioVisited } = useUser();
  const { points } = user;
  const previousPoints = usePrevious(points);

  const headerRef = useRef();
  const musicRef = useRef();
  const boxesRef = useRef();
  const stickyBoxesRef = useRef();
  const searchInputRef = useRef();
  const songsWrapperRef = useRef();

  const { ref: songsRef, inView } = useScrollObserver("songs");
  const [selectedParent, setParent] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [search, setSearch] = useState("");

  const { radioid, name } = useRadioidByNameseo(nameseo);

  const isSearch = location.search.indexOf("?s") === 0;

  const {
    isLoading,
    data,
    isError,
    error,
    radioid: currentRadioid,
  } = useLocal(radioid);

  useEffect(() => {
    setRadioVisited({lastradiovisited: {id: radioid, name: name}});
  }, [radioid]);

  useEffect(() => {
    setTimeout(() => {
      if (
        typeof BRANCH_OPTIONS[nameseo] !== "undefined" &&
        currentModal !== SELECT_BRANCH_MODAL &&
        currentModal !== TERMS_AND_CONDITIONS_MODAL
      ) {
        const branches = BRANCH_OPTIONS[nameseo].branches;
        let chosenBranch = getCookie(nameseo);
        if(Object.keys(branches).length === 1) {
          chosenBranch = Object.keys(branches)[0];
        }
        // console.log(chosenBranch);
        if (Object.keys(branches).indexOf(chosenBranch) === -1) {
          openSelectBranchModal({ nameseo: nameseo });
        } else {
          if (chosenBranch !== nameseo) {
            navigate(`/sonicbox/${chosenBranch}`);
          }
        }
      }
    }, 1000);
  }, [nameseo, openSelectBranchModal, currentModal, navigate]);

  useEffect(() => {
    // console.log("A local currentRadioid", currentRadioid, data);
    if (
      data?.name &&
      (data.name.indexOf("Falabella") > -1 ||
        data.name.indexOf("falabella") > -1)
    ) {
      setTheme("falabella");
    } else if (data?.name && data.name.indexOf("Casper Radio") > -1) {
      setTheme("casper");
    } else {
      setTheme("default");
    }
  }, [setTheme, data, currentRadioid]);

  useEffect(() => {
    if (lastModal === VOTED_SUCCESS_MODAL) {
      // console.log("-------------- VOTED_SUCCESS_MODAL ---------------");
      if (typeof headerRef.current !== "undefined" && headerRef.current) {
        scrollIntoView(headerRef.current);
      }
    }
  }, [lastModal]);

  // useEffect(() => {
  //   if (data?.radiobg && data.radiobg !== "") {
  //     document.body.style.backgroundImage = `url(${data.radiobg})`;
  //   }
  //   return () => (document.body.style.backgroundImage = null);
  // }, [data]);

  //const { isLoading, data, isError, error } = useLocal(radioid, null);

  const handleLetsPlay = () => {
    if (musicRef.current !== undefined) {
      scrollIntoView(musicRef.current, 75);
    }
  };

  const handleOnSelectBox = (selectedBox) => {
    setSearch("");
    setSelectedBox(selectedBox);
    // console.log(
    //   "scrollIntoView songsWrapperRef",
    //   songsWrapperRef,
    //   songsWrapperRef.current
    // );
    navigate(`/sonicbox/${nameseo}?s=&p=${selectedBox}`);
  };

  const handleScrollBack = () => {
    handleLetsPlay();
  };

  const handleOnClickInput = useCallback(
    (event) => {
      event.preventDefault();
      setTimeout(() => {
        if (searchInputRef.current !== undefined) {
          event.target.blur();
          searchInputRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setTimeout(() => {
            searchInputRef.current
              .getElementsByTagName("input")[0]
              .focus({ preventScroll: false });
          }, 500);
          // console.log("focus on other ref", searchInputRef.current);
        }
      }, 500);
      return false;
    },
    [searchInputRef]
  );

  useEffect(() => {
    const pointsInt = parseInt(points);
    const prePointsInt = parseInt(previousPoints);
    if (pointsInt > prePointsInt) {
      stackModal({ credits: pointsInt - prePointsInt });
    }
  }, [points, previousPoints, stackModal]);

  useEffect(() => {
    console.log("extraQuery", extraQuery?.data);
  }, [extraQuery.lastUpdateAt, extraQuery?.data]);

  useEffect(() => {
    // console.log("termsQuery", termsQuery?.data);
    // console.log("extraQuery", extraQuery?.data?.data);
    const extraArray =
      typeof extraQuery?.data?.data?.extra === "string"
        ? extraQuery?.data?.data?.extra?.split(",")
        : [];
    const termsAccepted = extraArray.includes("terms");
    // console.log("termsAccepted", extraArray, termsAccepted);
    if (termsQuery?.data && extraQuery?.data) {
      console.log(
        "currentModal",
        currentModal,
        TERMS_AND_CONDITIONS_MODAL,
        termsQuery.acceptTerms.status,
        termsAccepted
      );
      if (
        termsQuery.acceptTerms.status === "idle" &&
        !termsAccepted &&
        currentModal !== TERMS_AND_CONDITIONS_MODAL
      ) {
        console.log("openTermsModal", nameseo, currentModal);
        openTermsModal({ nameseo: nameseo });
      }
      if (
        termsAccepted &&
        currentModal === TERMS_AND_CONDITIONS_MODAL &&
        !openPeekTerms
      ) {
        // console.log("now close terms modal");
        closeModal();
      }
    }
  }, [
    termsQuery?.data,
    extraQuery?.data?.data?.extra,
    nameseo,
    openTermsModal,
    currentModal,
    closeModal,
    openPeekTerms,
    radioid,
    termsQuery.acceptTerms,
    termsQuery.acceptTerms.status,
    extraQuery?.data,
  ]);

  const selectedPlaylistName = useMemo(() => {
    return data?.playlists?.find(
      (value) => parseInt(value.playlistid) === parseInt(selectedBox)
    )?.playlistname;
  }, [selectedBox, data]);

  return (
    <div className="container">
      {isError ? (
        <DevPre style={{ color: "red" }}>
          {JSON.stringify(error, null, " ")}
        </DevPre>
      ) : isLoading ? (
        <Loader />
      ) : isSearch ? (
        <SearchMusic nameseo={nameseo} />
      ) : (
        <div className={["sonicbox"].join(" ")}>
          <NowPlaying {...data} radioid={radioid} />
          <header
            id="header"
            style={{
              backgroundImage: data?.radiobg ? `url(${data.radiobg})` : "",
            }}
            ref={headerRef}
          >
            <SonicboxHeader {...data} radioid={radioid} />
            <Button className="cta" size={"big"} onClick={handleLetsPlay}>
              <img src={PlayIcon} alt="play" className="icon" />
              <T>sonicbox.cta</T>
            </Button>
            {termsQuery?.data &&
              (!!termsQuery?.links ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {termsQuery.links.map((link, index) => (
                    <Button
                      className="brand"
                      as="link"
                      onClick={() => {
                        window.open(link.href, "_blank");
                      }}
                      key={index}
                      style={{ marginLeft: index === 0 ? 0 : 10, opacity: 0.5 }}
                    >
                      {link.textContent}
                    </Button>
                  ))}
                </div>
              ) : (
                <Button
                  className="brand"
                  as="link"
                  onClick={() => {
                    setOpenPeekTerms(true);
                    openTermsModal({
                      nameseo: nameseo,
                      isPeeking: true,
                      setIsPeeking: setOpenPeekTerms,
                    });
                  }}
                  style={{
                    marginLeft: "auto",
                    display: "block",
                    marginTop: 20,
                    marginBottom: -14,
                    marginRight: -8,
                    fontSize: 12,
                    opacity: 0.5,
                  }}
                >
                  <T>sonicbox.terms</T>
                </Button>
              ))}
          </header>
          <div id="boxes" ref={musicRef}>
            <Boxes
              ref={boxesRef}
              playlists={
                data
                  ? data.playlists.sort((playlist) =>
                      playlist.playlistid === "737" ? -1 : 1
                    )
                  : []
              }
              parents={data ? data.parents : []}
              onSelectBox={handleOnSelectBox}
              selectedBox={selectedBox}
              boxesRef={boxesRef}
              songsRef={songsRef}
              boxesOffset={80}
              songsOffset={180}
              selectedParent={selectedParent}
              setParent={setParent}
              title={data ? <T name={data.name}>sonicbox.boxestitle</T> : ""}
            />
          </div>
          <div id="suggested">
            <Suggested radio={data} />
          </div>
          <div id="artists">
            <Artists radio={data} />
          </div>
        </div>
      )}
      <Button
        className={["blue", "scroll-back-button", inView ? "show" : ""].join(
          " "
        )}
        onClick={handleScrollBack}
      >
        <img src={MenuToggleSVG} alt="ir arriba" />
      </Button>
    </div>
  );
};

export default Sonicbox;
